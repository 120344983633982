import { Accordion } from '../../Accordion';
import { FilterTopic } from '../types';
import TopicTitle from './TopicTitle';
import PeekText from './PeekText';
import TopicOptions from './TopicOptions';

const CurrentFilters = ({
  data,
  isOpen,
  onToggleFilter,
}: {
  data: FilterTopic[];
  isOpen: boolean;
  onToggleFilter: (filterKey: string, selected: boolean) => void;
}) => {
  return (
    <Accordion
      key={isOpen ? 'accordions-open' : 'accordions-closed'}
      name="filter-topics"
      data={data.map((f) => ({
        title: <TopicTitle title={f.label} />,
        peek: <PeekText filterTopic={f} />,
        body: <TopicOptions filterTopic={f} onToggleFilter={onToggleFilter} />,
        id: f.label,
      }))}
      collapsed
    />
  );
};

export default CurrentFilters;
