import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Text as Paragraph } from '../Typography';

export const Tag = tw(Paragraph)`
  whitespace-nowrap
  border
  rounded-2xl
  border-current
  px-4
  py-1
  mr-2
  mb-2.5
  text-gray-dark
  hover:bg-gray
  cursor-pointer
  flex
  items-center
`;

export const Tagslist = tw.div<{ $centered: boolean }>`
${({ $centered }) => ($centered ? 'justify-center' : 'justify-start')}
  flex
  flex-wrap
`;

export const ClearButton = styled.button`
  padding: 0.25rem 0.25rem 0.25rem 0;
  height: 1.125rem;
  svg {
    width: 0.625rem;
    height: 0.625rem;
  }
`;

export const Link = styled.button`
  text-decoration: none;
  color: #5a5e5a;
`;
