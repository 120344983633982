export const StarSmallOutline = ({ className, size }: { className?: string; size?: string }) => (
  <svg
    width={size || '15'}
    height={size || '14'}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className || 'inline-block'}
  >
    <path
      d="M13.8813 4.69112L9.89061 4.11713L8.08661 0.481803C7.78595 -0.146862 6.85661 -0.174195 6.52862 0.481803L4.75195 4.11713L0.733966 4.69112C0.0233017 4.80046 -0.250031 5.67512 0.269301 6.19445L3.13929 9.00978L2.45596 12.9731C2.34663 13.6838 3.11196 14.2304 3.74062 13.9024L7.32128 12.0164L10.8746 13.9024C11.5033 14.2304 12.2686 13.6838 12.1593 12.9731L11.4759 9.00978L14.3459 6.19445C14.8653 5.67512 14.5919 4.80046 13.8813 4.69112ZM10.5193 8.68178L11.2846 13.1098L7.32128 11.0324L3.33063 13.1098L4.09596 8.68178L0.870633 5.56579L5.32595 4.90979L7.32128 0.864469L9.28927 4.90979L13.7446 5.56579L10.5193 8.68178Z"
      fill="#5A5E5A"
    />
  </svg>
);
