import React, { createRef, useEffect, useState } from 'react';

import { TagList } from '../../TagList';
import { Text as Paragraph } from '../../Typography';

import type { IFilterTagListProps } from '../types';
import clsx from 'clsx';

export const FilterTagsList = ({
  tagList,
  labels,
  onClearFilter,
  searchLink,
}: IFilterTagListProps) => {
  const [listExpanded, setListExpanded] = useState<boolean>(false);
  const [canExpand, setCanExpand] = useState<boolean>(false);
  const listRef = createRef<HTMLDivElement>();

  useEffect(() => {
    setCanExpand((listRef.current?.clientHeight || 0) > 114);
  }, [tagList]);

  return (
    <>
      {tagList.length > 0 && (
        <div className="hidden md:block pt-[1.625rem] px-0 pb-0 mb-10">
          <div
            className={clsx('overflow-hidden mb-3', listExpanded ? 'max-h-auto' : 'max-h-[114px]')}
          >
            <div ref={listRef}>
              <TagList
                tags={tagList.map((t) => ({ id: t.key, label: t.label }))}
                clearable
                onClear={(tagId) => onClearFilter(tagId)}
                searchLink={searchLink}
              />
            </div>
          </div>
          {canExpand && (
            <button type="button" onClick={() => setListExpanded(!listExpanded)}>
              {listExpanded ? (
                <Paragraph tag="span" type="leadxs">
                  &uarr; {labels.showLess}
                </Paragraph>
              ) : (
                <Paragraph tag="span" type="leadxs">
                  &darr; {labels.showAll}
                </Paragraph>
              )}
            </button>
          )}
        </div>
      )}
    </>
  );
};
