import { CloseIcon } from '@icons/Close';
import { Text as Paragraph } from '../../Typography';

interface IClearFiltersControlProps {
  filtersCount: number;
  label?: string;
  onClear: () => void;
}

const ClearFiltersControl = ({
  filtersCount,
  label = 'Clear Filters',
  onClear,
}: IClearFiltersControlProps) => {
  if (filtersCount < 1) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={onClear}
      className="relative flex flex-row flex-nowrap items-center justify-start mb-4 after:content-[''] after:h-[1px] after:bg-bg-dark-gray after:absolute after:left-0 after:right-0 after:bottom-0"
    >
      <CloseIcon size={10} />{' '}
      <Paragraph tag="span" type="leadxs" className="ml-1">
        {label} ({filtersCount})
      </Paragraph>
    </button>
  );
};

export default ClearFiltersControl;
