import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect, useRef, useState } from 'react';
import { CloseIcon } from '@icons/Close';
import type { FilterOption, FilterTopic, IFilterMenuProps } from './types';
import { FilterTagsList } from './components/FilterTagsList';
import ClearFiltersControl from './components/ClearFiltersControl';
import CurrentFilters from './components/CurrentFilters';
import clsx from 'clsx';
import { Title } from '../Typography';

export const FilterMenu = ({
  id = 'filter-menu',
  isOpen,
  title,
  filters,
  onClose,
  onApplyFilters,
  totalResults,
  labels,
}: IFilterMenuProps) => {
  const [currentFilters, setCurrentFilters] = useState<FilterTopic[]>(filters);
  const [filtersDirty, setFiltersDirty] = useState<boolean>(false);
  const scrollLockRef = useRef(null);

  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);

  useEffect(() => {
    if (scrollLockRef.current) {
      if (isOpen) {
        disableBodyScroll(scrollLockRef.current);
      } else {
        enableBodyScroll(scrollLockRef.current);
      }
    }
  }, [isOpen]);

  const onToggleFilter = (filterKey: string, selected: boolean = false) => {
    const updatedFilters: FilterTopic[] = currentFilters.reduce(
      (updated, current: FilterTopic) => [
        ...updated,
        {
          ...current,
          options: current.options.map((opt) =>
            opt.key === filterKey ? { ...opt, selected } : opt,
          ),
        },
      ],
      [] as FilterTopic[],
    );
    setCurrentFilters(updatedFilters);
    setFiltersDirty(true);
    onApplyFilters(updatedFilters);
  };

  const clearAllFilters = () => {
    const updatedFilters: FilterTopic[] = currentFilters.map((cf: FilterTopic) => ({
      ...cf,
      options: cf.options.map((opt: FilterOption) => ({
        ...opt,
        selected: false,
      })),
    }));
    setCurrentFilters(updatedFilters);
    setFiltersDirty(true);
    onApplyFilters(updatedFilters);
  };

  const tagList: FilterOption[] = currentFilters.reduce((a: FilterOption[], t: FilterTopic) => {
    const selected: FilterOption[] = t.options.reduce(
      (opts: FilterOption[], curr: FilterOption) => (curr.selected ? [...opts, curr] : opts),
      [] as FilterOption[],
    );
    return [...a, ...selected];
  }, [] as FilterOption[]);

  return (
    <>
      {isOpen && (
        <div className="bg-black h-full fixed left-0 w-full bg-opacity-75 block transition-all duration-500 ease-in-out drawer-overlay top-header focus:outline-none focus:border-red-300 focus:ring z-20" />
      )}
      <section
        id={id}
        className={clsx(
          'fixed inset-0 p-0 md:left-auto md:inset-y-0 md:w-filterNav bg-white flex flex-col top-headerMobile md:top-header transition-all duration-500 ease-in-out',
          isOpen
            ? 'right-0 opacity-100 z-50 pointer-events-auto'
            : 'right-[-39.125rem] opacity-0 z-0 pointer-events-none',
        )}
      >
        <header className="bg-white top-0 inset-x-0 flex flex-row flex-wrap p-5 pb-0 md:p-20 md:pb-5">
          <Title
            tag="div"
            type="xs"
            className="w-auto mr-auto md:mr-[1.875rem] md:w-[200px] mb-4 order-1 "
          >
            {title}
          </Title>
          <div className="felx order-2 md:order-4 basis-auto md:basis-full p-0 md:p-4 justify-start md:justify-center ">
            <ClearFiltersControl
              filtersCount={tagList.length}
              label={labels.clearAllFilters}
              onClear={() => clearAllFilters()}
            />
          </div>
          <button className="ml-auto order-3" type="button" onClick={onClose}>
            <CloseIcon size={24} />
          </button>
          <div className="basis-full h-0" />
        </header>
        <div
          ref={scrollLockRef}
          className="overflow-y-auto px-5 md:px-20 pb-20 md:pb-52 relative z-[-1] bg-gradient-to-b from-[#00000033] to-transparent bg-no-repeat bg-[length:100%_20px] before:content-[''] before:absolute before:block before:z-[-1] before:h-[60px] before:mx-0 before:mt-0 before:mb-[-60px] before:left-0 before:right-0 before:top-0  before:bg-gradient-to-b before:from-[#fff] before:via-30% before:via-[#fff] before:to-[#ffffff00]"
        >
          <FilterTagsList
            tagList={tagList}
            labels={labels}
            onClearFilter={(key: string) => onToggleFilter(key)}
          />
          <CurrentFilters data={currentFilters} isOpen={isOpen} onToggleFilter={onToggleFilter} />
          <div className="bg-gradient-to-b from-[#ffffff00] from-0% via-28% via-[#ffffff85] to-54% to-[#fff]  px-p py-14 fixed bottom-0 left-0 right-0 w-full text-xs md:hidden">
            <button
              type="button"
              className="ml-[50%] -translate-x-1/2"
              onClick={() => {
                onApplyFilters(currentFilters);
                setFiltersDirty(false);
                onClose();
              }}
            >
              {filtersDirty
                ? `${labels.applyFilters} (${totalResults})`
                : `${labels.showAllResults} (${totalResults})`}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};
