import { StarSmallOutline } from '@icons/StarSmallOutline';
import { StarSmall } from '@icons/StarSmall';

const DisplayStars = ({ r }: { r: number | string }) => (
  <div className="inline-block mr-1">
    {[1, 2, 3, 4, 5].map((n) =>
      n > parseInt(`${r}`, 10) ? (
        <StarSmallOutline key={`rating-star-${n}`} size={'22'} className="inline-block mr-2" />
      ) : (
        <StarSmall key={`rating-star-${n}`} size={'22'} className="inline-block mr-2" />
      ),
    )}
  </div>
);

export default DisplayStars;
