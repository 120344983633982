import { FilterOption, FilterTopic } from '../types';
import { Text as Paragraph } from '../../Typography';

const PeekText = ({ filterTopic }: { filterTopic: FilterTopic }) => {
  const contents = filterTopic.options.reduce((res: string, curr: FilterOption) => {
    if (curr.selected) {
      return res.length > 0 ? [res, curr.label].join(', ') : curr.label;
    }
    return res;
  }, '');

  if (!contents.length) {
    return null;
  }

  return (
    <Paragraph
      tag="div"
      type="sm"
      className="Paragraph-gray-dark pt-0.5 whitespace-nowrap truncate"
    >
      {contents}
    </Paragraph>
  );
};

export default PeekText;
