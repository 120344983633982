import React from 'react';
import { CloseIcon } from '@icons/Close';
import { Tag, Tagslist, ClearButton, Link } from './styles';

export interface ITag {
  label: string;
  id?: string;
}

export interface ITagListProps {
  tags: ITag[];
  centered?: boolean;
  clearable?: boolean;
  onClear?: (id: string) => void;
  searchLink?: string;
}

export const TagList = ({
  tags,
  centered = false,
  clearable = false,
  searchLink = '/search?category=products&filters=',
  onClear = () => {},
}: ITagListProps) => {
  const handelLink = (tagTitle: string, url: string) => {
    // Create anchor element.
    const a: any = document.createElement('a');
    // Create the text node for anchor element.
    const text = document.createTextNode('This is link');
    a.appendChild(text);
    // Set the href property.
    a.href = url;
    // Set the rel property.
    a.rel = 'noopener noreferrer';
    // Set the target property.
    a.target = '__blank';
    // Set the id property.
    a.id = `${tagTitle}-unique-tag`;
    a.click();
  };
  return (
    <Tagslist $centered={centered}>
      {tags &&
        tags.map((tag) => (
          <Tag tag="div" type="lead" key={tag.id || tag.label}>
            {clearable && (
              <ClearButton type="button" onClick={() => onClear(tag.id || tag.label)}>
                <CloseIcon />
              </ClearButton>
            )}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              type="button"
              onClick={() =>
                handelLink(
                  tag.label,
                  `${searchLink}${encodeURIComponent(`meta_keywords:${tag?.label}`)}`,
                )
              }
            >
              {tag.label}
            </Link>
          </Tag>
        ))}
    </Tagslist>
  );
};
